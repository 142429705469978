@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;700&display=swap');

body {
  margin: 0;
  font-family:
    'Barlow',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fira-text {
  font-family: 'Fira Sans', sans-serif;
}
input {
  font-family: 'Fira Sans', sans-serif;
  font-weight: 300;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
